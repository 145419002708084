@import url('https://fonts.googleapis.com/css2?family=Muli:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
$primary-color : #ffffff;
$font-color : #292929;



*{
    padding: 0;
    margin: 0;
    font-family: 'Muli', sans-serif;
    box-sizing: border-box;
    outline: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    color: var(--fg);

    transition: background 300ms, color 300ms;
}

body{
    --bg : white;
    --fg : #292929;
    --tc : #0779e4;
    background: var(--bg);
    &.default{
        --bg : white;
        --fg : #292929;
        --tc : #0779e4;
    }
    
    &.dark{
        --bg : #292929;
        --fg : white;
    }

    &.red{
        --bg : white;
        --fg : #292929;
        --tc : #e8505b;
    }
}




/* Components */
.Header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 9vh;
    display: flex;
    align-items: center;
    color: var(--fg);
    z-index: 999;

    .brand{
        cursor: pointer;
        mix-blend-mode: difference;
        position: relative;
        margin: 0 5%;
        font-weight: bold;
        letter-spacing: 2px;
        font-family: 'Press Start 2P', cursive;
        &::before{
            z-index: -1;
            content: '';
            position: absolute;
            top: -10%;
            right: -25%;
            width: 50%;
            height: 100%;
            background: var(--tc);
        }
    }

    .wrapper{
        position: absolute;
        top: 50%;
        right: 5%;
        transform: translateY(-50%);
        .menu{
            z-index: 999;
            all : unset;
            font-weight: 800;
            transition: 500ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
            padding: 6px 12px;
            line{ transition:  500ms;}
            &.open{
                transform: rotate(90deg);
            }
        }
    
        nav{
            position: absolute;
            background: var(--tc);
            right: 0;
            width: 300px;
            height: 330px;
            transition: 500ms;
            padding: 12px 24px;
            &:not(.open){
                opacity: 0;
                z-index: -10;
                visibility: hidden;
                transform: translateX(10px);
                span, a{
                    opacity: 0;
                    transform: translateX(-10px);
                }
                &:nth-child(4){transition-delay: 50ms;}
                &:nth-child(3){transition-delay: 100ms;}
                &:nth-child(2){transition-delay: 150ms;}
                &:nth-child(1){transition-delay: 200ms;}
            }
            &.open{
                opacity: 1;
                z-index: 99999999999999;
                visibility: visible;
                transform: translateX(0);
                box-shadow:
                        0 1.3px 3.6px rgba(0, 0, 0, 0.024),
                        0 3.5px 10px rgba(0, 0, 0, 0.035),
                        0 8.4px 24.1px rgba(0, 0, 0, 0.046),
                        0 28px 80px rgba(0, 0, 0, 0.07);
            }
            span, a{
                position: relative;
                color: #ffffff;
                font-size: xx-large;
                font-weight: 900;
                letter-spacing: 4px;
                cursor: pointer;
                text-decoration: none;
                transition: 500ms;
                display: block;
                line-height: 65px;
                transform: translateX(0);

                &:nth-child(1){transition-delay: 50ms;}
                &:nth-child(2){transition-delay: 100ms;}
                &:nth-child(3){transition-delay: 150ms;}
                &:nth-child(4){transition-delay: 200ms;}
                &:hover{
                    color: #f4f6ff;
                }
                &::before{
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 5px;
                    left: 0;
                    bottom: 0;
                    background: #f4f6ff;
                    transition: 500ms;
                }
                &.active{
                    &::before{
                        width: 10%;
                    }
                }
            }
        }
    } 
}

.App{
    color: var(--fg);
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    animation: fadeUpShow 500ms;
    .window{
        border-radius: 8px;
        box-shadow:
            0 1.3px 3.6px rgba(0, 0, 0, 0.024),
            0 3.5px 10px rgba(0, 0, 0, 0.035),
            0 8.4px 24.1px rgba(0, 0, 0, 0.046),
            0 28px 80px rgba(0, 0, 0, 0.07);
        position: absolute;
        top: 25%;
        left: 14%;
        background: var(--bg);
        width : 256px;
        height: 200px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        justify-items: center;
        color: var(--fg);
        img{ margin-top: 16px; grid-column: 2/3; border-radius: 50%; }
        .row-2{grid-row: 2/3; grid-column: 1/4; text-align: center; margin-top: 18px;}
        .row-3{
            width: 100%; grid-row: 3/3; grid-column: 1/4; text-align: center; margin-top: -10px; display: flex; align-items: center; justify-content: space-evenly;
            span{
                transform: translateY(10px);
                opacity: 0;
                transition: 450ms;
                &:nth-child(2){transition-delay: 100ms;}
                &:nth-child(3){transition-delay: 150ms;}

            }
        }
        transition: 500ms;
        opacity: 0;
        z-index: -1;
        transform: translateY(-10px);

        &.open{
            opacity: 1;
            z-index: 999;
            transform: translateY(0);

            .row-3 span{
                transform: translateY(0px);
                opacity: 1;
            }
            
        }

        
    }
    .mainWrapper{
        z-index: 2;
        width: 80%;
        
        @media (max-width : 480px) {
            width: 90%;
        }

        p.b{
            z-index: 2;
            .intro{font-size:  110%;}
            margin: 64px 0;
            @media (max-width : 480px) {
                font-size: 1.2rem;
            }
            font-size: 1.4rem;
        }
    }

    .smallWrapper{
        width: 80%;
        display: flex;
        flex-direction: row;
        @media (max-width : 768px) {
            width: 90%;
            flex-direction: column;
        }

        .left, .right{
            width: 50%;
            display: flex;
            flex-direction: column;
            @media (max-width : 480px) {
                width: 100%;
            }
            .title{
                font-size: 200%;
                font-weight: 100;
                margin: 3% 0;
                animation: showTitle 1s;
                opacity: .9;
                transition: 500ms;
                width: fit-content;
                cursor: pointer;
    
                &:hover{
                    opacity: 1;
    
                }
                
                &:nth-child(2){
                    animation-delay: 100ms;
                }
                &:nth-child(3){
                    animation-delay: 150ms;
                }
                &:nth-child(4){
                    animation-delay: 200ms;
                }

                &.active{
                    font-size: 210%;
                    color: var(--tc);
                }
                
            }
        }

        .right{
            flex-direction: row;
            position: relative;
            .box{
                position: absolute;
                width: 100%;
                min-height: 226px;
                z-index: -2;
                visibility: hidden;
                opacity: 0;
                transform: scale(1.1) translateY(30px);
                background: var(--bg);
                @media (max-width : 480px) {
                    margin-bottom: 20px;
                }

                
               
                
                .container{
                    border-radius: 6px;
                    width: 100%;
                    display: flex;
                    justify-content: space-evenly;
                    min-height: 226px;
                    position: relative;
                    padding: 8px;
                    box-shadow:
                        0 1.3px 3.6px rgba(0, 0, 0, 0.024),
                        0 3.5px 10px rgba(0, 0, 0, 0.035),
                        0 8.4px 24.1px rgba(0, 0, 0, 0.046),
                        0 28px 80px rgba(0, 0, 0, 0.07);
                }


                
                &.active{
                    visibility: visible;
                    z-index: auto;
                    opacity: 1;
                    transform: scale(1) translateY(0);
                    border-radius: 6px;
                    width: 100%;
                    flex-basis: 100%;
                    transition: 500ms;
                    border-radius: px;
                    /*
                    box-shadow:
                        0 1.3px 3.6px rgba(0, 0, 0, 0.024),
                        0 3.5px 10px rgba(0, 0, 0, 0.035),
                        0 8.4px 24.1px rgba(0, 0, 0, 0.046),
                        0 28px 80px rgba(0, 0, 0, 0.07);
                    */
                }
            }
        }
    }

        
}


.About{
    width: 100vw;
    display: flex;
    justify-content: center;
    
    .mainWrapper{
        width: 95%;
        padding: 20px 16px;
        min-height: 200px;
        .bigHead{
            animation : fadeUpShow 750ms;
            margin-left: 20px;
            display: block;
        }

        .pg{
            display: block;
            font-size: 140%;
            animation : fadeUpShow 750ms 10ms;

            
        }
    }
}


.Themer{
    z-index: 99999;
    position: fixed;
    left : 7%;
    bottom: 7%;
    background: white;
    border-radius: 999999px;
    width: 50px;
    height: 50px;
    box-shadow:
        0 1.3px 3.6px rgba(0, 0, 0, 0.024),
        0 3.5px 10px rgba(0, 0, 0, 0.035),
        0 8.4px 24.1px rgba(0, 0, 0, 0.046),
        0 28px 80px rgba(0, 0, 0, 0.07);
    &:not(.open){
        display: flex;
        justify-content: center;
        align-items: center;

        .themes{
            display: none;
        }
    }
    &.open{
        

        .themeIcon{
            display: none;
        }
    }
    .themes{
        border: 2px solid white;
        width: 100%;
        height: 100%;
        border-radius: 9999999999999px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        span{
            display: block;
            height: 100%;
            flex: 1;
            &:first-child{
                border-radius: 999999px 0 0 999999px;
            }
            &:last-child{
                border-radius: 0 999999px 999999px 0;
            }
        }
    }
}

.Lab{
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadeUpShow 500ms;
}

/* Keyframes */

@keyframes showTitle{
    from{
        transform: translateY(-10px);
        opacity: .7;
    }
    to{
        transform: translateY(0px);
        opacity: .9;
    }
}

@keyframes fadeUpShow{
    from{
        opacity: 0;
        transform: translateY(10px);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
}

















/* Globals */
code{
    font-family: monospace;
    font-size: 100%;
}
tag{
    position: relative;
    transition: 450ms;
    cursor: pointer;
    &:before{
        border-radius: 999px;
        z-index: -1;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        transition: 450ms;
        background: linear-gradient(89deg, hsl(200.60000000000002, 100%, 48.4%) 38%, hsl(188.20000000000005, 100%, 44.3%) 100%);
        opacity: .8;
    }

    &:hover{
        &::before{
            //background: linear-gradient(141deg, hsl(196.70000000000005, 35.3%, 33.9%) 0%, hsl(183.10000000000002, 66.3%, 34.9%) 70%);
            opacity: 1;
        }
    }
}

[link]{
    color: var(--tc);
    text-decoration: none;
    transition: 500ms;
    font-size: 80%;
    &:hover{
        color: #0779e499;
    }
}


